import axios from "axios";
import emojiFlags from "emoji-flags";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_RENDER_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const truncate = (str: string) => {
  const truncatedStr = str.slice(0, 6) + "..." + str.slice(-6);
  return truncatedStr;
};

export const formatDateTime = (isoString: string): string => {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true, // For 12-hour format
  };

  return date.toLocaleString("en-US", options);
};

export const initials = (value: string) => {
  const nameParts = value.trim().split(" ");

  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";

  return `${firstInitial}${lastInitial}`;
};

export const getCountryDetails = (
  countryName: string
): TCountryDetails | null => {
  const country = Object.values(emojiFlags).find(
    (flag) => flag.code === countryName
  );

  // console.log(emojiFlags);

  if (country) {
    return {
      code: country.code,
      emoji: country.emoji,
      name: country.name,
    };
  }

  return null;
};

export const generateRandomNumber = (digits: number): number => {
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return randomNum;
};
