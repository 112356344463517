import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { Skeleton } from "../ui/Skeleton";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Statistics({
  performance,
  total_predictions,
  win_los_ratio,
  total_points,
  average_points_per_prediction,
  success_rate,
  isLoading,
}: any) {
  return (
    <div className="mt-8 px-8 space-y-4">
      <h3 className="text-[28px] leading-none">Player Statistics</h3>

      {isLoading ? (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            <Skeleton className="col-span-1 sm:col-span-2 row-span-2 aspect-[2.5] w-full rounded-lg" />

            <Skeleton className="w-full rounded-lg" />
            <Skeleton className="w-full rounded-lg" />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <Skeleton className="aspect-[2.5] w-full rounded-lg" />
            <Skeleton className="aspect-[2.5] w-full rounded-lg" />
            <Skeleton className="aspect-[2.5] w-full rounded-lg" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            <div className="col-span-1 sm:col-span-2 row-span-2 aspect-[2.5] w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col gap-4 px-5 py-4">
              <h4 className="text-muted-foreground">Performance Over Time</h4>
              <ChartComponent performance={performance} />
            </div>
            <div className="w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col px-5 py-6">
              <p className="text-base text-muted-foreground">
                Total Predictions Made
              </p>
              <h2 className="flex items-end justify-between mt-auto">
                <span className="font-normal">{total_predictions || 0}</span>
              </h2>
            </div>
            <div className="w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col px-5 py-6">
              <p className="text-base text-muted-foreground">Win/Loss Ratio:</p>
              <h2 className="flex items-end justify-between mt-auto">
                <span className="font-normal">{win_los_ratio || 0}</span>
                <IoArrowUpOutline className="size-6 text-[#73DB9F]" />
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="aspect-[2.5] w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col px-5 py-6">
              <p className="text-base text-muted-foreground">Success Rate:</p>
              <h2 className="flex items-end justify-between mt-auto">
                <span className="font-normal">
                  {success_rate.toFixed(1) || 0}%
                </span>
                <IoArrowUpOutline className="size-6 text-[#73DB9F]" />
              </h2>
            </div>
            <div className="aspect-[2.5] w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col px-5 py-6">
              <p className="text-base text-muted-foreground">
                Average Points per Prediction
              </p>
              <h2 className="flex items-end justify-between mt-auto">
                <span className="font-normal">
                  {average_points_per_prediction.toFixed(1) || 0}
                </span>
                <IoArrowUpOutline className="size-6 text-[#73DB9F]" />
              </h2>
            </div>
            <div className="aspect-[2.5] w-full [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))] border border-border/20 ring-1 ring-black/50 rounded-lg flex flex-col px-5 py-6">
              <p className="text-base text-muted-foreground">Total Points</p>
              <h2 className="flex items-end justify-between mt-auto">
                <span className="font-normal">{total_points || 0}</span>
                <IoArrowDownOutline className="size-6 text-[#FF897D]" />
              </h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const ChartComponent = ({ performance }: any) => {
  const minRound = Math.min(...performance.map((item: any) => item.round));
  const maxRound = Math.max(...performance.map((item: any) => item.round));

  const rounds = Array.from(
    { length: maxRound - minRound + 6 },
    (_, i) => `R${minRound + i}`
  );

  const points = rounds.map((roundLabel: any) => {
    const roundNumber = parseInt(roundLabel.substring(1), 10);
    const foundRound = performance.find(
      (data: any) => data.round === roundNumber
    );
    return foundRound ? Number(foundRound.totalPoints) : 0;
  });

  const minPoints = Math.min(...points, 0);
  const maxPoints = Math.max(...points) + 1;

  const data = {
    labels: rounds,
    datasets: [
      {
        label: "",
        data: points,
        borderColor: "#24935E",
        backgroundColor: "rgba(36, 147, 94, 0.2)",
        fill: false,
        tension: 0.4,
        borderWidth: 1,
        pointRadius: 5,
        pointStyle: "circle",
      },
    ],
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      y: {
        min: minPoints,
        max: maxPoints,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
      },
      x: {
        offset: false,
        grid: {
          display: false,
        },
        ticks: {
          padding: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: "#FFFFFF",
        titleColor: "#000000",
        bodyColor: "#000000",
        callbacks: {
          title: function (tooltipItems: any) {
            const roundNumber = tooltipItems[0].label;
            return `Round ${roundNumber}`;
          },
          label: function (tooltipItem: any) {
            const points = tooltipItem.raw;
            return `${points}pts`;
          },
        },

        custom: function (tooltipModel: any) {
          const { body, title } = tooltipModel;
          if (!body) {
            return null;
          }

          const tooltipHtml = `
            <div style="background: #FFFFFF; color: #000000; padding: 16px; border-radius: 4px; border: 1px solid #000000;">
              <div style="font-size: 12px;"><strong>${title[0]}</strong></div>
              <div>${body[0].lines[0]}</div>
            </div>
          `;

          const tooltipEl = document.createElement("div");
          tooltipEl.innerHTML = tooltipHtml;
          document.body.appendChild(tooltipEl);

          const { offsetLeft, offsetTop } = tooltipModel.caretX;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left = `${offsetLeft}px`;
          tooltipEl.style.top = `${offsetTop}px`;

          return tooltipEl;
        },
      },
    },
  };

  return <Line className="!p-0 !w-full" data={data} options={options} />;
};
