import { FormEvent, ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";
import { X } from "lucide-react";
import { Button } from "../ui/Button";
import InputField from "./InputeField";
import { IoMailOutline } from "react-icons/io5";
import { cn } from "utils";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { invitePlayer } from "pages/auth/controller/Auth.Controller";
import { useFetchCurrentUser } from "hooks/useFetchCurrentUser";
import { useToast } from "hooks/useToast";
import { TbLoader } from "react-icons/tb";

export default function AddAdmin({ children }: { children: ReactNode }) {
  const dispatch = useDispatch<AppDispatch>();

  const { accessToken } = useFetchCurrentUser();
  const { showToast } = useToast();

  const initialFormValues = {
    email: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const validateInputs = async (formData: any) => {
    const rules = {
      email: [
        { check: (value: string) => !!value, message: "Email is required" },
        {
          check: (value: string) => /\S+@\S+\.\S+/.test(value),
          message: "Invalid email address",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<any> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof any];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field] = rule.message;
          hasError = true;
          break;
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const hasError = await validateInputs(formData);

    if (!hasError) {
      setIsLoading(true);
      dispatch(invitePlayer({ email: formData.email, accessToken }))
        .then((data: any) => {
          if (data.payload.success) {
            showToast({ message: data.payload?.message, type: "success" });
            setFormData(initialFormValues);
          } else {
            showToast({
              message: data.payload?.message || data?.payload?.error,
              type: "error",
            });
          }
        })
        .catch((error) => {
          showToast({
            message: error.message || "Something went wrong",
            type: "error",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-lg p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Add New Admin</p>

          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <form onSubmit={handleSubmit} className="flex items-end gap-4">
            <InputField
              name="email"
              type="email"
              placeholder="Invite others by email"
              value={formData.email}
              onChange={handleChange}
              error={errors.email}
              icon={IoMailOutline}
              disabled={isLoading}
            />

            <Button
              className={cn("h-12", {
                "mb-5": errors.email,
              })}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> Please
                  wait...
                </>
              ) : (
                "Save Changes"
              )}
            </Button>
          </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
