import { createBrowserRouter } from "react-router-dom";

import AuthLayout from "layouts/AuthLayout";
import SignIn from "pages/auth/views/SignIn";
import SignUp from "pages/auth/views/SignUp";
import RequestPasswordReset from "pages/auth/views/RequestPasswordReset";
import Confirmation from "pages/auth/views/Confirmation";
import NewPassword from "pages/auth/views/NewPassword";
import Success from "pages/auth/views/Success";
import DashboardLayout from "layouts/DashboardLayout";
import Players from "pages/dashboard/views/Players";
import PlayerInfo from "pages/dashboard/views/PlayerInfo";
import Matches from "pages/dashboard/views/Matches";
import VerifyOTP from "pages/auth/views/VerifyOTP";

export const routes = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <SignIn />,
      },
      {
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "/sign-up",
        element: <SignUp />,
      },
      {
        path: "/request-password",
        element: <RequestPasswordReset />,
      },
      {
        path: "/email-confirmation/:email",
        element: <Confirmation />,
      },
      {
        path: "/verify-otp/:email",
        element: <VerifyOTP />,
      },
      {
        path: "/new-password",
        element: <NewPassword />,
      },
      {
        path: "/success",
        element: <Success />,
      },
    ],
  },
  {
    element: <DashboardLayout />,
    children: [
      {
        path: "/players",
        element: <Players />,
      },
      {
        path: "/players/:id",
        element: <PlayerInfo />,
      },
      {
        path: "/matches",
        element: <Matches />,
      },
      {
        path: "/settings",
        element: <p>Settings</p>,
      },
    ],
  },
]);
