import { useDispatch } from "react-redux";
import { useLocalStorage } from "./useLocalStorage";
import { AppDispatch } from "state/store";
import { getPlayerData } from "pages/dashboard/controller/Player.Controller";
import { useState } from "react";
import { useToast } from "./useToast";

export const useFetchCurrentUser = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [responseData, setResponseData] = useState<TFetchResponse<TCredential>>(
    {
      message: "",
      success: false,
      pending: false,
      data: undefined,
    }
  );

  const { showToast } = useToast();
  const { getFn } = useLocalStorage("credential");
  const credential: TCredential | undefined = getFn();

  const fetchUser = () => {
    if (!credential) {
      setResponseData({
        message: "Credential not found",
        success: false,
        pending: false,
        data: undefined,
      });
      return;
    }

    setResponseData((prev) => ({ ...prev, pending: true }));

    dispatch(
      getPlayerData({
        userId: credential.id,
        accessToken: credential.accessToken,
      })
    )
      .then((data) => {
        if (data.payload.success) {
          setResponseData({
            message: "",
            success: true,
            pending: false,
            data: data.payload.data,
          });
        } else {
          setResponseData({
            message: data.payload.message,
            success: false,
            pending: false,
            data: undefined,
          });
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        setResponseData({
          message: error?.message || "Could not fetch user data",
          success: false,
          pending: false,
          data: undefined,
        });
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      });
  };

  return { fetchUser, responseData, accessToken: credential?.accessToken };
};
