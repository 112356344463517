import { FormEvent, useState } from "react";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { TbLoader } from "react-icons/tb";

import InputField from "common/components/shared/InputeField";
import { PasswordStrengthIndicator } from "common/components/shared/PasswordStrengthIndicator";
import { Button } from "common/components/ui/Button";
import toast from "react-hot-toast";
import CustomToast from "common/components/shared/CustomToast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { resetPassword } from "../controller/Auth.Controller";

export default function NewPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const initialFormValues: IResetPassword = {
    password: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);
  const [isPasswordValid, setIsPasswordValid] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  const [isPasswordMatching, setIsPasswordMatching] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password: string) => {
    setIsPasswordValid({
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
    });
  };

  const passwordCriteria = [
    { label: "6 characters", isValid: isPasswordValid.length },
    { label: "Uppercase", isValid: isPasswordValid.uppercase },
    { label: "Lowercase", isValid: isPasswordValid.lowercase },
    { label: "Number", isValid: isPasswordValid.number },
    { label: "Password Matching", isValid: isPasswordMatching },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));

    if (name === "password" || name === "confirmPassword") {
      validatePassword(value);

      if (name === "confirmPassword") {
        setIsPasswordMatching(value === formData.password && value !== "");
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "password") {
      validatePassword(value);
    }
    if (name === "confirmPassword") {
      setIsPasswordMatching(value === formData.password && value !== "");
    }
  };

  const validateInputs = async (formData: IResetPassword) => {
    const rules = {
      password: [
        {
          check: (value: string) => !!value,
          message: "Password is required",
        },
        {
          check: (value: string) => value.length >= 6,
          message: "Password must be at least 6 characters long",
        },
        {
          check: (value: string) => /[A-Z]/.test(value),
          message: "Password must contain an uppercase letter",
        },
        {
          check: (value: string) => /[a-z]/.test(value),
          message: "Password must contain a lowercase letter",
        },
        {
          check: (value: string) => /[0-9]/.test(value),
          message: "Password must contain a number",
        },
      ],
      confirmPassword: [
        {
          check: (value: string) => !!value,
          message: "Please confirm your password",
        },
        {
          check: (value: string) => value === formData.password,
          message: "Passwords do not match",
        },
      ],
    };

    let hasError = false;
    const newErrors: Partial<IResetPassword> = {};

    Object.keys(rules).forEach((field) => {
      const value = formData[field as keyof IResetPassword];
      const fieldRules = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors[field as keyof IResetPassword] = rule.message;
          hasError = true;
          break;
        }
      }
    });

    setErrors(newErrors as any);
    return hasError;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hasError = await validateInputs(formData);

    if (!hasError) {
      setIsLoading(true);
      dispatch(resetPassword({ newPassword: formData.password }))
        .then((data) => {
          if (data.payload.success) {
            navigate("/sign-in");
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  message="Password reset successfully"
                  type="success"
                />
              ),
              {
                duration: 5000,
              }
            );
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  message={data.payload.message}
                  type="error"
                />
              ),
              {
                duration: 5000,
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                message={error?.message || "Could not sign up"}
                type="error"
              />
            ),
            {
              duration: 5000,
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[550px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">Set New Password</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Create a new password that’s easy for you to remember but hard for
            others to guess.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-4"
          >
            <div
              key="password-group"
              className="flex flex-col sm:flex-col md:flex-row gap-3 md:gap-4"
            >
              <InputField
                name="password"
                type={showPassword ? "text" : "password"}
                label="Enter Password"
                placeholder="xxxx xxxx xxxx xxxx"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password}
                icon={showPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowPassword((prev) => !prev)}
                disabled={isLoading}
              />
              <InputField
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                placeholder="xxxx xxxx xxxx xxxx"
                value={formData.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword}
                icon={showConfirmPassword ? GoEyeClosed : GoEye}
                onIconClick={() => setShowConfirmPassword((prev) => !prev)}
                disabled={isLoading}
              />
            </div>

            {/* Password Strength Indicators */}
            <PasswordStrengthIndicator criteria={passwordCriteria} />

            <Button
              type="submit"
              variant={"secondary"}
              disabled={isLoading}
              className="w-full mt-8"
            >
              {isLoading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> Please
                  wait...
                </>
              ) : (
                "Creat New Password"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
