import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border-[0.5px] px-[12px] py-[1.5px] text-sm font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        success:
          "border-primary bg-primary/20 text-primary shadow hover:bg-primary/30",
        danger:
          "border-[#FF634E] bg-[#FF634E]/10 text-[#FF634E] shadow hover:bg-[#FF634E]/20",
        progress:
          "border-foreground bg-foreground/10 text-foreground shadow hover:bg-foreground/20",
        paused:
          "border-[#FFD600] bg-[#FFD600]/10 text-[#FFD600] shadow hover:bg-[#FFD600]/20",
        schedule:
          "border-[#2BFFFF] bg-[#2BFFFF]/20 text-[#2BFFFF] shadow hover:bg-[#2BFFFF]/30",
      },
    },
    defaultVariants: {
      variant: "success",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
