import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { apiClient } from "utils";

export const getMatches = createAsyncThunk(
  "app/getMatches",
  async (request: TGetMatchesPayload, { rejectWithValue }) => {
    const params = new URLSearchParams();
    if (request.status !== "all")
      params.append("prediction_status", request.status!);
    if (request.round !== "all") params.append("round", request.round!);
    params.append("page", request.page || "0");
    params.append("size", request.size || "100");

    try {
      const response = await apiClient.get(
        `/match/admin?${params.toString()}`,
        {
          headers: { Authorization: `Bearer ${request.accessToken}` },
        }
      );

      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(
          err.response?.data || "An unexpected error occurred"
        );
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const addMatch = createAsyncThunk(
  "app/addMatch",
  async ({ accessToken, matchData }: any, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/match", matchData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const getMatchById = createAsyncThunk(
  "app/getMatchById",
  async ({ accessToken, matchId }: any, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/match/${matchId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const updateMatch = createAsyncThunk(
  "app/updateMatch",
  async (
    { accessToken, matchId, updateData }: TUpdateMatch,
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.put(`/match/${matchId}`, updateData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const deleteMatch = createAsyncThunk(
  "app/deleteMatch",
  async ({ accessToken, matchId }: TDeleteMatch, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(`/match/${matchId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);
