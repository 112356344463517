import { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LuSearch } from "react-icons/lu";
import { Button, buttonVariants } from "common/components/ui/Button";
import { MdSwapVert } from "react-icons/md";
import { Skeleton } from "common/components/ui/Skeleton";
import { Link } from "react-router-dom";
import { cn, getCountryDetails, initials, truncate } from "utils";
import { assets } from "assets";
import { TbCopy, TbCopyCheck } from "react-icons/tb";
import { useFetchCurrentUser } from "hooks/useFetchCurrentUser";
import { usePlayers } from "hooks/usePlayers";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";

export default function Players() {
  const filter = useSelector((state: any) => state.app.filter);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 100;

  const { fetchUser, responseData: userData } = useFetchCurrentUser();
  const {
    getAllPlayers,
    players,
    isFetchingPlayers,
    total_players,
    searchPlayerByUsername,
  } = usePlayers();
  const { copiedItemId, copyToClipboard } = useCopyToClipboard();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userData.success) {
      getAllPlayers({
        size: itemsPerPage,
        page: currentPage,
      });
    }
  }, [userData.success, filter, currentPage]);

  const totalPages = Math.ceil(total_players / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      getAllPlayers({
        size: itemsPerPage,
        page: currentPage + 1,
      });
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 0) {
      return;
    }
    getAllPlayers({
      size: itemsPerPage,
      page: currentPage - 1,
    });
    setCurrentPage(currentPage - 1);
  };

  const handleSubmit = (e: any) => {
    if (searchTerm.trim() === "") return;
    e.preventDefault();
    if (e.key === "Enter") {
      searchPlayerByUsername({ username: searchTerm });
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto">
      <header className="border-b p-4 border-border/30 h-[74px]">
        <div className="flex items-center h-full gap-3">
          <FaRegUserCircle className="size-6" />
          <h4>Players</h4>
        </div>
      </header>

      <section className="p-4">
        <div className="flex flex-col">
          <h4>Player Management</h4>
          <p className="text-xs text-muted-foreground">
            Manage player profiles, including blocking, unblocking, and deleting
            users.
          </p>
        </div>

        <div className="flex flex-col gap-6 mt-8">
          {/* Filter */}
          <div className="flex items-center justify-between">
            <h5 className="flex items-end gap-2">
              All Players
              <span className="text-muted-foreground text-base">
                {total_players}
              </span>
            </h5>

            <div className="flex items-center gap-4">
              <div className="h-10 w-[381px] relative">
                <LuSearch className="size-4 absolute top-1/2 -translate-y-1/2 left-3 text-muted-foreground" />
                <input
                  className="border border-border/30 focus:border-border ring-1 ring-black outline-none rounded-lg bg-secondary size-full pl-9 pr-4 text-foreground placeholder:text-muted-foreground text-sm"
                  placeholder="Search for players"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value.trim() === "") {
                      setTimeout(() => {
                        if (userData.success) {
                          getAllPlayers({
                            size: itemsPerPage,
                            page: currentPage,
                          });
                        }
                      }, 1000);
                    }
                  }}
                  onKeyUp={handleSubmit}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col border border-border/30 rounded-2xl overflow-hidden">
            <div className="flex items-center [background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] px-4 py-3">
              <div className="flex items-center gap-2 w-[23%]">
                <p className="text-sm">Name</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center gap-2 w-[20%]">
                <p className="text-sm">Username</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center justify-center gap-2 flex-1">
                <p className="text-sm">Country</p>
              </div>
              <div className="flex items-center justify-center gap-2 flex-1">
                <p className="text-sm">Social Media</p>
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Wallet Address</p>
              </div>
            </div>

            <div className="flex flex-col">
              {isFetchingPlayers ? (
                Array.from({ length: 10 }).map((_, _key) => (
                  <div
                    key={_key}
                    className="h-[74px] flex items-center px-4 border-t border-border/30"
                  >
                    <div className="flex items-center py-4 gap-4 w-[23%]">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 gap-2 w-[20%]">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 justify-center gap-2 flex-1">
                      <Skeleton className="size-8 rounded-full" />
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 justify-center gap-4 flex-1">
                      <Skeleton className="size-9 rounded-lg" />
                      <Skeleton className="size-9 rounded-lg" />
                    </div>
                    <div className="flex items-center py-4 gap-4 flex-1 group cursor-pointer">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                  </div>
                ))
              ) : players?.length === 0 ? (
                <div className="aspect-[3.2] bg-secondary/50 flex items-center justify-center">
                  <h5 className="">No Player Found</h5>
                </div>
              ) : (
                players?.map((player: TCredential) => {
                  const country_details = getCountryDetails(
                    String(player?.nationality)
                  );
                  return (
                    <div
                      key={player?.id}
                      className="h-[74px] flex items-center px-4 border-t border-border/30 hover:[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] transition-all"
                    >
                      <div className="flex items-center gap-4 w-[23%]">
                        <div className="flex items-center justify-center size-10 rounded-lg border border-border/20 ring-1 ring-black [background:_radial-gradient(103.31%_110%_at_43.75%_21.25%,_rgba(255,_255,_255,_0)_0%,_rgba(255,_255,_255,_0.123)_55.28%,_rgba(255,_255,_255,_0.246)_100%)]">
                          <h5 className="font-medium">
                            {initials(
                              `${player?.firstname} ${player?.lastname}`
                            )}
                          </h5>
                        </div>

                        <Link
                          to={`/players/${player?.id}`}
                          className="flex flex-col flex-1"
                        >
                          <p className="text-sm text-foreground font-medium line-clamp-1">
                            {player?.firstname} {player?.lastname}
                          </p>
                          <span className="text-xs text-muted-foreground/80 font-medium line-clamp-1">
                            {player?.email}
                          </span>
                        </Link>
                      </div>
                      <div className="flex items-center gap-2 w-[20%]">
                        <p className="text-sm text-muted-foreground">
                          {player?.username}
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-2 flex-1">
                        {player?.nationality ? (
                          <>
                            <div className="text-3xl">
                              {country_details?.emoji}
                            </div>
                            <p className="text-sm text-muted-foreground">
                              {country_details?.name}
                            </p>
                          </>
                        ) : (
                          <p className="text-sm text-muted-foreground">N/A</p>
                        )}
                      </div>
                      <div className="flex items-center justify-center gap-4 flex-1">
                        {!player.x_profile_link &&
                        !player.telegram_user_name ? (
                          <p className="text-sm text-muted-foreground">N/A</p>
                        ) : (
                          [
                            {
                              condition: player.x_profile_link,
                              src: assets.svgs.xIcon,
                              alt: "X Icon",
                              link: `https://x.com/${player.x_profile_link}`,
                            },
                            {
                              condition: player.telegram_user_name,
                              src: assets.svgs.insIcon,
                              alt: "Instagram Icon",
                              link: `https://t.me/${player.telegram_user_name?.replace(
                                "@",
                                ""
                              )}`,
                            },
                          ].map(({ condition, src, alt, link }, index) =>
                            condition ? (
                              <Link
                                to={link}
                                key={index}
                                target="_blank"
                                className={buttonVariants({
                                  size: "icon",
                                  variant: "secondary",
                                })}
                              >
                                <img
                                  className="size-6"
                                  src={src}
                                  alt={alt}
                                  width={24}
                                  height={24}
                                />
                              </Link>
                            ) : null
                          )
                        )}
                      </div>

                      <div className="flex items-center gap-4 flex-1 group">
                        {player?.wallet ? (
                          <>
                            <p className="text-sm text-muted-foreground line-clamp-1">
                              {player?.wallet && truncate(player?.wallet)}
                            </p>
                            <span
                              className={cn(
                                "hidden group-hover:block cursor-pointer",
                                {
                                  block: copiedItemId === player?.id,
                                }
                              )}
                            >
                              {copiedItemId === player?.id ? (
                                <TbCopyCheck className="size-4 text-primary" />
                              ) : (
                                <TbCopy
                                  onClick={async () =>
                                    copyToClipboard(
                                      String(player?.wallet),
                                      player?.id
                                    )
                                  }
                                  className="size-4"
                                />
                              )}
                            </span>
                          </>
                        ) : (
                          <p className="text-sm text-muted-foreground line-clamp-1">
                            N/A
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {(total_players > itemsPerPage * (currentPage + 1) ||
            currentPage > 0) && (
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-4">
                <p className="text-sm text-muted-foreground">
                  Page {currentPage + 1} of{" "}
                  {Math.ceil(total_players / itemsPerPage)}
                </p>
                <Button
                  size={"sm"}
                  variant={"secondary"}
                  onClick={handlePrevPage}
                  disabled={currentPage === 0}
                >
                  Prev
                </Button>
                <Button
                  size={"sm"}
                  variant={"secondary"}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages - 1}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
