import { buttonVariants } from "common/components/ui/Button";
import { Link } from "react-router-dom";

export default function Success() {
  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[484px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">Password Reset</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            Password successfully reset. Click below log in
          </p>
        </div>

        <Link
          to="/sign-in"
          typeof="button"
          className={buttonVariants({
            variant: "secondary",
            className: "w-full mt-8",
          })}
        >
          Login
        </Link>
      </div>
    </div>
  );
}
