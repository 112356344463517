import { useState } from "react";
import { useToast } from "./useToast";

export const useCopyToClipboard = () => {
  const { showToast } = useToast();

  const [copiedItemId, setCopiedItemId] = useState<string | null>(null);

  const copyToClipboard = async (text: string, id: string | null) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedItemId(id);

      showToast({
        message: "Successfully copied to clipboard",
        type: "success",
        duration: 2000,
      });
      setTimeout(() => {
        setCopiedItemId(null);
      }, 2500);
    } catch (error: any) {
      showToast({
        message: "Failed to copied to clipboard",
        type: "error",
      });
      console.error("Failed to copy: ", error);
      setCopiedItemId(null);
    }
  };

  return { copiedItemId, copyToClipboard };
};
