import Sidebar from "common/components/shared/Sidebar";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function DashboardLayout() {
  const navigate = useNavigate();

  const { getFn } = useLocalStorage("credential");

  const credential = getFn();

  useEffect(() => {
    if (credential === undefined) {
      navigate("/sign-in", { replace: true });
    }
  }, []);

  return (
    <div className="min-h-dvh w-full antialiased flex flex-1 relative">
      <Sidebar />

      <div className="py-4 pr-4 flex-1 h-screen">
        <div className="border border-border/70 rounded-lg h-full bg-secondary/50 overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
