import authBg from "./images/auth-bg.jpeg";
import logoIcon from "./images/logo.png";
import profileBanner from "./images/profile-banner.png";

import xIcon from "./svgs/xIcon.svg";
import insIcon from "./svgs/insIcon.svg";

export const assets = {
  images: {
    authBg,
    logoIcon,
    profileBanner,
  },
  svgs: {
    xIcon,
    insIcon,
  },
};
