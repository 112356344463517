import { useState } from "react";
import { useFetchCurrentUser } from "./useFetchCurrentUser";
import {
  addMatch,
  deleteMatch,
  getMatchById,
  getMatches,
  updateMatch,
} from "pages/dashboard/controller/Match.Controller";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { useToast } from "./useToast";
import { apiClient } from "utils";

export const useMatch = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accessToken } = useFetchCurrentUser();
  const { showToast } = useToast();

  const [matches, setMatches] = useState<TMatch[]>([]);
  const [total_matches, set_total_matches] = useState(0);
  const [isFetchingMatches, setIsFetchingMatches] = useState<boolean>(true);
  const [isFetchingSingleMatch, setIsFetchingSingleMatch] = useState(false);
  const [isAddingMatch, setIsAddingMatch] = useState<boolean>(false);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isDeletingMatch, setIsDeletingMatch] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [singleMatch, setSingleMatch] = useState<TMatch | undefined>();

  const setBulkMatches = (_matches: TMatch[]) => {
    setMatches(_matches);
  };

  const setLoadingState = (state: boolean) => {
    setIsFetchingMatches(state);
  };

  const searchMatches = async (query: string) => {
    try {
      setIsFetchingMatches(true);
      const response = await apiClient.get(`/match/search?query=${query}`);

      if (response.data.success) {
        setMatches(response.data?.data?.rows);
      }
    } catch (error: any) {
      showToast({
        message: error?.message || "Could not fetch user data",
        type: "error",
      });
    }
    setIsFetchingMatches(false);
  };

  const getAllMatches = ({
    round,
    status,
    page,
    size,
  }: {
    round: string;
    status: string;
    page: string;
    size: string;
  }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    setIsFetchingMatches(true);

    dispatch(getMatches({ accessToken, round, status, page, size }))
      .then((data) => {
        if (data.payload.success) {
          // console.log({ response: data.payload.data?.rows });
          setMatches(data.payload.data?.rows);
          set_total_matches(data.payload.data?.count);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsFetchingMatches(false);
      });
  };

  const getSingleMatchById = ({ matchId }: any) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    setIsFetchingSingleMatch(true);

    dispatch(getMatchById({ accessToken, matchId }))
      .then((data) => {
        if (data.payload.success) {
          console.log(data.payload.data);
          setSingleMatch(data.payload.data);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsFetchingSingleMatch(false);
      });
  };

  const addNewMatch = ({ matchData, success_callback }: any) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    setIsAddingMatch(true);

    dispatch(addMatch({ accessToken, matchData }))
      .then((data) => {
        if (data.payload.success) {
          setMatches((prev) => [data.payload.data, ...prev]);
          showToast({ message: data?.payload?.message, type: "success" });
          setShowModal(false);
          success_callback();
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsAddingMatch(false);
      });
  };

  const updateMatchData = ({
    updateData,
    matchId,
  }: {
    updateData: any;
    matchId: string;
  }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    setIsUpdating(true);

    dispatch(updateMatch({ accessToken, matchId, updateData }))
      .then((data) => {
        if (data.payload.success) {
          setMatches((prev) =>
            prev.map((match) =>
              match.id === matchId ? data.payload.data : match
            )
          );
          showToast({ message: data?.payload?.message, type: "success" });
          setShowUpdateModal(false);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not update match data",
          type: "error",
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const add_single_match = (match: TMatch) => {
    setMatches([match, ...matches]);
  };

  const deleteMatchById = ({ matchId }: { matchId: string }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }

    setIsDeletingMatch(matchId);

    dispatch(deleteMatch({ accessToken, matchId }))
      .then((data) => {
        if (data.payload.success) {
          setMatches((prev) => prev.filter((match) => match.id !== matchId));
          showToast({ message: data?.payload?.message, type: "success" });
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsDeletingMatch(null);
      });
  };

  return {
    matches,
    isFetchingMatches,
    isAddingMatch,
    isDeletingMatch,
    isUpdating,
    isFetchingSingleMatch,
    getSingleMatchById,
    getAllMatches,
    addNewMatch,
    updateMatchData,
    deleteMatchById,
    showModal,
    setShowModal,
    total_matches,
    singleMatch,
    showUpdateModal,
    setShowUpdateModal,
    setBulkMatches,
    searchMatches,
    setLoadingState,
    add_single_match,
  };
};
