import CustomToast from "common/components/shared/CustomToast";
import toast from "react-hot-toast";

export const useToast = () => {
  const showToast = ({
    message,
    type,
    duration = 5000,
  }: {
    message: string;
    type: "success" | "pending" | "info" | "error";
    duration?: number;
  }) => {
    toast.custom(
      (t) => <CustomToast toast={t} message={message} type={type} />,
      { duration: duration }
    );
  };

  return { showToast };
};
