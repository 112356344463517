import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addMatch,
  deleteMatch,
  getMatchById,
  getMatches,
  updateMatch,
} from "pages/dashboard/controller/Match.Controller";
import {
  getPlayerData,
  getPlayers,
  getPlayerStats,
  searchPlayer,
} from "pages/dashboard/controller/Player.Controller";

interface FilterState {
  round: string | number;
  prediction: "all" | "true" | "false";
}

interface AppState {
  user: any;
  status: "idle" | "loading" | "success" | "failed";
  error: any;
  isVerified: boolean;
  rememberDevice: boolean;
  filter: FilterState;
}

const initialState: AppState = {
  user: null,
  status: "idle",
  error: null,
  isVerified: false,
  rememberDevice: false,
  filter: {
    round: "all",
    prediction: "all",
  },
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FilterState>) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlayers.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(searchPlayer.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(getPlayerData.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(getPlayerStats.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(getMatches.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(getMatchById.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(addMatch.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(updateMatch.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(deleteMatch.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.status = "failed";
        }
      );
  },
});

export const { setFilter } = appSlice.actions;
export default appSlice.reducer;
