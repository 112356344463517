import { createSlice, PayloadAction, Action } from "@reduxjs/toolkit";
import {
  resetPassword,
  requestOtp,
  signIn,
  signUp,
  verifyEmail,
} from "pages/auth/controller/Auth.Controller";

const initialState: TAuthState = {
  user: null,
  status: "idle",
  error: null,
  isVerified: false,
  rememberDevice: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.isVerified = true;
      })
      .addCase(
        signIn.fulfilled,
        (
          state,
          action: Action & {
            payload: TCredential;
            meta: { arg: TSignInPayload };
          }
        ) => {
          state.user = action.payload;
          state.rememberDevice = action.meta.arg.rememberDevice;
        }
      )
      .addCase(resetPassword.fulfilled, (state) => {
        state.user = null;
        state.status = "success";
      })
      .addCase(requestOtp.fulfilled, (state) => {
        state.status = "success";
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action: PayloadAction<any>) => {
          state.error = action.payload;
          state.status = "failed";
        }
      );
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
