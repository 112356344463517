import { MdCheck } from "react-icons/md";
import { cn } from "utils";

interface PasswordCriteria {
  label: string;
  isValid: boolean;
}

interface PasswordStrengthIndicatorProps {
  criteria: PasswordCriteria[];
}

export const PasswordStrengthIndicator: React.FC<
  PasswordStrengthIndicatorProps
> = ({ criteria }) => {
  return (
    <div className="flex items-start gap-2 flex-wrap">
      {criteria.map((criterion, index) => (
        <div
          key={index}
          className={cn(
            "border border-border rounded-[10px] py-1 px-2.5 flex items-center gap-1.5",
            {
              "border-[#6EE99B80] text-[#4ADE80]": criterion.isValid,
              "opacity-50 cursor-not-allowed": !criterion.isValid,
            }
          )}
        >
          <p className="text-sm">{criterion.label}</p>
          <MdCheck size={16} />
        </div>
      ))}
    </div>
  );
};
