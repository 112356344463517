import { Link } from "react-router-dom";
import { getCountryDetails } from "utils";
import { Skeleton } from "../ui/Skeleton";

export default function PersonalDetails({
  username,
  firstname,
  lastname,
  email,
  nationality,
  telegram_user_name,
  x_profile_link,
  wallet,
  isLoading,
}: any) {
  return (
    <div className="mt-8 flex flex-col gap-8 px-8">
      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Personal Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Fullname</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">
                {firstname} {lastname}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">email</p>
            {isLoading ? (
              <Skeleton className="h-4 w-56 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{email}</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Username</p>
            {isLoading ? (
              <Skeleton className="h-4 w-20 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{username}</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">
              Country / Region of Residence
            </p>
            {isLoading ? (
              <div className="flex items-center gap-2">
                <Skeleton className="size-4 rounded-full border border-border/20" />
                <Skeleton className="h-4 w-14 rounded-md border border-border/20" />
              </div>
            ) : (
              <p className="flex items-center gap-2">
                <span className="text-2xl">
                  {getCountryDetails(nationality)?.emoji}
                </span>
                <span className="text-base">{nationality || "N/A"}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Social Network Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Telegram</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : telegram_user_name ? (
              <Link
                to={`https://t.me/${telegram_user_name}`}
                target="_blank"
                className="text-base"
              >
                {telegram_user_name}
              </Link>
            ) : (
              <p className="text-base">N/A</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Twitter</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : x_profile_link ? (
              <Link
                to={`https://x.com/${x_profile_link}`}
                target="_blank"
                className="text-base"
              >
                {x_profile_link}
              </Link>
            ) : (
              <p className="text-base">N/A</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Wallet Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">
              Binance ID or USDT
            </p>
            {isLoading ? (
              <Skeleton className="h-4 w-80 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{wallet || "N/A"}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
