import { Loader } from "lucide-react";
import React from "react";
import { IoCheckmarkDone } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import { IoInformationSharp } from "react-icons/io5";
import { cn } from "utils";

export default function CustomToast({
  type,
  message,
  toast,
}: {
  type?: "success" | "pending" | "info" | "error";
  message: string;
  toast?: any;
}) {
  return (
    <div
      style={{
        opacity: toast && toast.visible ? 1 : 0,
      }}
      className={cn(
        "max-w-xs w-full p-4 rounded-xl border-l-8 border border-border/10 ring-1 ring-foreground/10 flex gap-4 transition-all ease-in-out duration-200 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]",
        {
          "border-l-primary": type === "success",
          "border-l-[#FF634E]": type === "error",
          "border-l-foreground": type === "pending",
          "border-l-[#2BFFFF]": type === "info",
        }
      )}
    >
      <div className="bg-secondary size-10 rounded-full flex items-center justify-center border border-border/30">
        {type === "success" ? (
          <IoCheckmarkDone className="size-6 text-primary" />
        ) : type === "error" ? (
          <MdClose className="size-6 text-[#FF634E]" />
        ) : type === "pending" ? (
          <Loader className="size-5 animate-spin text-foreground" />
        ) : (
          <IoInformationSharp className="size-6 text-[#2BFFFF]" />
        )}
      </div>

      <div className="flex flex-col flex-1">
        <p className="text-xs text-muted-foreground">
          {type === "success"
            ? "Success"
            : type === "error"
            ? "Error"
            : type === "pending"
            ? "Pending"
            : "Informative"}{" "}
          notification
        </p>
        <p className="text-sm">{message}</p>
      </div>
    </div>
  );
}
