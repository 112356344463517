import {
  useEffect,
  useState,
  FormEvent,
  ReactNode,
  useCallback,
  useMemo,
  PropsWithChildren,
  useRef,
} from "react";
import { IoFilterSharp, IoFootballOutline } from "react-icons/io5";
import { Skeleton } from "common/components/ui/Skeleton";
import { apiClient, cn, formatDateTime } from "utils";
import { Badge } from "common/components/ui/Badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import { LuSearch } from "react-icons/lu";
import FilterMatch from "common/components/shared/FilterMatch";
import { Button } from "common/components/ui/Button";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { MdSwapVert } from "react-icons/md";
import { useFetchCurrentUser } from "hooks/useFetchCurrentUser";
import { useMatch } from "hooks/useMatch";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "state/store";
import { setFilter } from "state/slices/appSlice";

import { X } from "lucide-react";
import { FaRegKeyboard } from "react-icons/fa6";
import { RiCalendarTodoLine } from "react-icons/ri";
import { generateRandomNumber } from "utils";
import { TbLoader } from "react-icons/tb";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "common/components/ui/Dialog";
import InputField from "common/components/shared/InputeField";
import toast from "react-hot-toast";

const initialFormValues = {
  home: "",
  away: "",
  venue: "",
  timestamp: "",
  round: 1,
};

export default function Matches() {
  const dispatch = useDispatch<AppDispatch>();
  const filter = useSelector((state: any) => state.app.filter);
  const [isAddMatch, setIsAddMatch] = useState<boolean>(false);
  const [new_matches, set_new_matches] = useState<TMatch[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [adding_api_matches, set_adding_api_matches] = useState<any>({});

  const [formData, setFormData] =
    useState<typeof initialFormValues>(initialFormValues);
  const [errors, setErrors] =
    useState<typeof initialFormValues>(initialFormValues);
  const [last_games, set_last_games] = useState<any>({});
  const itemsPerPage = 100;
  const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

  const { fetchUser, responseData: userData } = useFetchCurrentUser();
  const {
    getAllMatches,
    deleteMatchById,
    matches,
    isFetchingMatches,
    isDeletingMatch,
    setBulkMatches,
    addNewMatch,
    isAddingMatch,
    showModal,
    setShowModal,
    showUpdateModal,
    setShowUpdateModal,
    getSingleMatchById,
    singleMatch,
    isFetchingSingleMatch,
    updateMatchData,
    isUpdating,
    setLoadingState,
    searchMatches,
    add_single_match,
    total_matches,
  } = useMatch();

  useEffect(() => {
    fetchUser();
  }, []);

  // const [currentPage, setCurrentPage] = useState(0);

  // const itemsPerPage = 100;

  useEffect(() => {
    if (userData.success) {
      getAllMatches({
        round: filter.round,
        status: filter.prediction,
        page: currentPage.toString(),
        size: itemsPerPage.toString(),
      });
    }
  }, [userData.success]);

  useEffect(() => {
    getAllMatches({
      round: filter.round,
      status: filter.prediction,
      page: "0",
      size: "100",
    });
  }, [filter]);

  const handleFilterChange = (newFilter: any) => {
    dispatch(setFilter(newFilter));
  };

  const validateInputs = (formData: typeof initialFormValues) => {
    const rules = {
      home: [
        {
          check: (value: string) => !!value,
          message: "Home team is required",
        },
      ],
      away: [
        {
          check: (value: string) => !!value,
          message: "Away team is required",
        },
      ],
      venue: [
        { check: (value: string) => !!value, message: "Venue is required" },
      ],
      timestamp: [
        {
          check: (value: string) => !!value,
          message: "Timestamp is required",
        },
      ],
      round: [
        {
          check: (value: number) => value > 0,
          message: "Round must be greater than 0",
        },
      ],
    };

    let hasError = false;
    let newErrors: any = {};

    Object.keys(rules).forEach((field) => {
      const value: any = formData[field as keyof typeof formData];
      const fieldRules: any = rules[field as keyof typeof rules];

      for (const rule of fieldRules) {
        if (!rule.check(value)) {
          newErrors = rule.message;
          hasError = true;
          break;
        }
      }
    });

    setErrors(newErrors);
    return hasError;
  };

  const handleAddMatch = (e: FormEvent) => {
    e.preventDefault();

    if (!last_games.home || !last_games.away)
      return toast.error("Last games not set");

    const home_last_games = Object.values(last_games.home);
    if (home_last_games.length < 5 || home_last_games.includes(""))
      return toast.error("Incomplete home last games");

    const away_last_games = Object.values(last_games.away);
    if (away_last_games.length < 5 || away_last_games.includes(""))
      return toast.error("Incomplete away last games");
    // console.log(Object.values(last_games.home));
    // return;
    const hasError = validateInputs(formData);

    if (!hasError) {
      const matchId = generateRandomNumber(6);

      const formField = {
        id: matchId,
        round: Number(formData.round),
        details: {
          fixture: {
            id: matchId,
            date: new Date(formData.timestamp).toISOString(),
            venue: {
              name: formData.venue,
            },
          },
          last_games: {
            home: home_last_games,
            away: away_last_games,
          },

          teams: {
            home: {
              name: formData.home,
            },
            away: {
              name: formData.away,
            },
          },
        },
      };

      addNewMatch({
        matchData: formField,
        success_callback: () => {
          setFormData({
            away: "",
            home: "",
            round: 1,
            timestamp: "",
            venue: "",
          });
          set_last_games({});
        },
      });
    } else {
      // console.log("here", errors);
    }
  };

  const handleDeleteMatch = (matchId: string) => {
    deleteMatchById({ matchId });
    set_match_to_delete(null);
    set_open_delete_confirmation(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const handleGameChange = (spot: string, index: string, value: string) => {
    set_last_games({
      ...last_games,
      [spot]: {
        ...last_games[spot],
        [index]: value.toString().toLowerCase() === "choose" ? "" : value,
      },
    });
  };

  const totalPages: any = Math.ceil(total_matches / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      getAllMatches({
        round: filter.round,
        status: filter.prediction,
        page: (currentPage + 1).toString(),
        size: itemsPerPage.toString(),
      });
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 0) {
      return;
    }
    getAllMatches({
      round: filter.round,
      status: filter.prediction,
      page: (currentPage - 1).toString(),
      size: itemsPerPage.toString(),
    });
    setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page - 1);
  };

  let addMatchProps = {
    handleChange,
    handleAddMatch,
    formData,
    errors,
    last_games,
    isAddingMatch,
    setShowModal,
    showModal,
    handleGameChange,
  };

  async function get_api_matches(date: undefined | string) {
    try {
      setLoadingState(true);
      const credential = localStorage.getItem("credential");
      const response = await apiClient.get("/match/api", {
        params: {
          date,
        },
        headers: {
          Authorization: `bearer ${
            credential ? JSON.parse(credential).accessToken : ""
          }`,
        },
      });

      if (response.data.success) {
        // console.log(response.data, "here at");
        const matches_id = matches.map((mp) => mp.id.toString());
        const structured_matches: TMatch[] = response.data.data.response
          .map((mp: any) => {
            return {
              id: mp.fixture.id.toString(),
              open: true,
              round: Number(mp.league?.round?.split(" ").pop()),
              details: {
                fixture: mp.fixture,
                league: mp.league,
                teams: mp.teams,
              },
            };
          })
          .filter((ft: TMatch) => !matches_id.includes(ft.id.toString()));
        set_new_matches(structured_matches);
      } else {
        toast.error("Timeout! Pls try again");
      }
      setLoadingState(false);
    } catch (error: any) {
      setLoadingState(false);
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
  }

  useEffect(() => {
    try {
      if (isAddMatch) {
        if (isFetchingMatches) return;
        get_api_matches(undefined);
      } else {
        set_new_matches([]);
        set_api_search_result([]);
        set_is_api_match_search(false);
        // setLoamedingState(false);
      }
    } catch (error: any) {
      setLoadingState(false);
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
  }, [isAddMatch]);

  const [open_score_modal, set_open_score_modal] = useState<boolean>(false);
  const [score_match, set_score_match] = useState<TMatch | null>(null);
  const [is_api_match_search, set_is_api_match_search] =
    useState<boolean>(false);
  const [api_search_results, set_api_search_result] = useState<TMatch[]>([]);
  const [api_rounds, set_api_rounds] = useState<any>({});

  const [match_to_delete, set_match_to_delete] = useState<TMatch | null>(null);
  const [open_delete_confirmation, set_open_delete_confirmation] =
    useState(false);

  return (
    <div className="flex flex-col overflow-y-auto">
      <AddScoreModal
        success_callback={() => {
          getAllMatches({
            round: filter.round,
            status: filter.prediction,
            page: currentPage.toString(),
            size: itemsPerPage.toString(),
          });
          set_open_score_modal(false);
          set_score_match(null);
        }}
        open={open_score_modal}
        match={score_match}
        onOpenChange={() => {
          set_open_score_modal(false);
          set_score_match(null);
        }}
      />

      <DeleteConfirmation
        handle_submit={handleDeleteMatch}
        loading={!!isDeletingMatch}
        match={match_to_delete}
        open={open_delete_confirmation}
        onOpenChange={() => {
          set_match_to_delete(null);
          set_open_delete_confirmation(false);
        }}
      />

      <header className="border-b p-4 border-border/30 h-[74px]">
        <div className="flex items-center h-full gap-3">
          <IoFootballOutline className="size-6" />
          <h4>Matches</h4>
        </div>
      </header>

      <section className="p-4">
        <div className="flex flex-col">
          <h4>Match Management</h4>
          <p className="text-xs text-muted-foreground">
            Add and manage matches manually or automatically by pulling from an
            API.
          </p>
        </div>

        <div className="flex flex-col gap-6 mt-8">
          <div className="flex items-center justify-between">
            <h5 className="flex items-end gap-2">
              All Fixtures
              <span className="text-muted-foreground text-base">
                {total_matches}
              </span>
            </h5>

            <div className="flex items-center gap-4">
              <form
                action="submit"
                onSubmit={async (e: FormEvent) => {
                  e.preventDefault();
                  if (!isAddMatch) {
                    await searchMatches(searchTerm.trim());
                  }
                }}
              >
                <div className="h-10 w-[381px] relative">
                  <LuSearch className="size-4 absolute top-1/2 -translate-y-1/2 left-3 text-muted-foreground" />
                  <input
                    className="border border-border/30 focus:border-border ring-1 ring-black outline-none rounded-lg bg-secondary size-full pl-9 pr-4 text-foreground placeholder:text-muted-foreground text-sm"
                    placeholder="Find fixtures"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);

                      if (isAddMatch) {
                        if (e.target.value.trim() === "") {
                          setTimeout(() => {
                            set_is_api_match_search(false);
                          }, 1000);
                        } else {
                          set_is_api_match_search(true);
                          const filtered_result = new_matches.filter(
                            (ft) =>
                              ft.details.teams.home.name
                                .toLowerCase()
                                .trim()
                                .includes(
                                  e.target.value.toLowerCase().trim()
                                ) ||
                              ft.details.teams.away.name
                                .toLowerCase()
                                .trim()
                                .includes(e.target.value.toLowerCase().trim())
                          );

                          // console.log(filtered_result);

                          set_api_search_result(filtered_result);
                        }
                      } else {
                        if (e.target.value.trim() === "") {
                          setTimeout(() => {
                            if (userData.success) {
                              getAllMatches({
                                round: filter.round,
                                status: filter.prediction,
                                page: currentPage.toString(),
                                size: itemsPerPage.toString(),
                              });
                            }
                          }, 1000);
                        }
                      }
                    }}
                  />
                </div>
              </form>

              <FilterMatch
                is_api_match={isAddMatch}
                on_apply={(value) => {
                  get_api_matches(value);
                }}
                on_reset={() => {
                  get_api_matches(undefined);
                }}
                onFilterChange={handleFilterChange}
              >
                <Button variant={"secondary"}>
                  <IoFilterSharp className="size-4 mr-2" />
                  Filter
                </Button>
              </FilterMatch>

              {isAddMatch ? (
                <Button
                  onClick={() => {
                    setIsAddMatch(false);
                  }}
                  variant={"destructive"}
                >
                  <AiOutlineClose className="size-4 mr-2" />
                  Close
                </Button>
              ) : (
                <AddMatch
                  {...addMatchProps}
                  get_api_matches={() => {
                    setIsAddMatch(true);
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    variant={"default"}
                  >
                    <AiOutlinePlus className="size-4 mr-2" />
                    Add Match
                  </Button>
                </AddMatch>
              )}
            </div>
          </div>

          <div className="flex flex-col border border-border/30 rounded-2xl overflow-hidden">
            <div className="flex items-center [background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] px-4 py-3">
              <div className="flex items-center gap-2 w-[11%]">
                <p className="text-sm">Date & Time</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center w-[410px]">
                <div className="flex items-center justify-end px-8 gap-2 flex-1">
                  <p className="text-sm">Home Team</p>
                </div>
                <span className="w-4" />
                <div className="flex items-center justify-start px-8 gap-2 flex-1">
                  <p className="text-sm">Away Team</p>
                </div>
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Round</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Status</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Prediction</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Match ID</p>
              </div>
            </div>

            <div className="flex flex-col">
              {isFetchingMatches ? (
                Array.from({ length: 10 }).map((_, _key) => (
                  <div
                    key={_key}
                    className="h-[74px] flex items-center px-4 border-t border-border/30"
                  >
                    <div className="flex items-center gap-4 w-[11%]">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center w-[410px]">
                      <div className="flex items-center justify-end px-8 gap-2 flex-1">
                        <Skeleton className="h-3 flex-1" />
                      </div>

                      <Skeleton className="size-4" />

                      <div className="flex items-center justify-start px-8 gap-2 flex-1">
                        <Skeleton className="h-3 flex-1" />
                      </div>
                    </div>
                    <div className="flex items-center py-4 gap-2 flex-1">
                      <Skeleton className="h-3 w-1/2" />
                    </div>
                    <div className="flex items-center py-4 gap-2 flex-1">
                      <Skeleton className="h-3 w-1/2" />
                    </div>
                    <div className="flex items-center py-4 gap-4 flex-1">
                      <Skeleton className="h-4 w-1/2" />
                    </div>
                    <div className="flex items-center py-4 gap-4 flex-1">
                      <Skeleton className="h-3 w-1/2" />
                      <Skeleton className="size-4 ml-auto" />
                    </div>
                  </div>
                ))
              ) : (isAddMatch
                  ? is_api_match_search
                    ? api_search_results
                    : new_matches
                  : matches
                ).length === 0 ? (
                <div className="aspect-[3.2] bg-secondary/50 flex items-center justify-center">
                  <h5 className="">No Match Found</h5>
                </div>
              ) : (
                (isAddMatch
                  ? is_api_match_search
                    ? api_search_results
                    : new_matches
                  : matches
                )?.map((match: TMatch) => {
                  let updateMatchProps = {
                    matchId: matches.filter((mt: any) => mt.id === match?.id),
                    showUpdateModal,
                    setShowUpdateModal,
                    updateMatchData,
                    isUpdating,
                  };

                  const currentTime = new Date(); // Get the current time
                  const targetDate = new Date(match.details.fixture.date);
                  const closed_prediction: boolean =
                    targetDate.getTime() - currentTime.getTime() <=
                    TEN_MINUTES_IN_MS;

                  return (
                    <div
                      key={match?.id}
                      className="h-[74px] flex items-center px-4 border-t border-border/30 hover:[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] transition-all relative"
                    >
                      {isDeletingMatch === match?.id && (
                        <div className="absolute top-0 left-0 w-full h-full bg-background/50 backdrop-blur-2xl animate-pulse" />
                      )}
                      <div className="flex items-center gap-4 w-[11%]">
                        <p className="text-sm text-muted-foreground">
                          {formatDateTime(match.details.fixture.date)}
                        </p>
                      </div>
                      <div className="flex items-center w-[410px]">
                        <div className="flex items-center justify-end px-8 gap-2 flex-1">
                          <p className="text-sm">
                            {match.details.teams.home?.name}
                          </p>
                          {match.details.goals ? (
                            <div className="border-2 border-border w-8 h-8 flex items-center justify-center">
                              <p className="text-sm">
                                {match.details.goals?.home}
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <span className="w-4 text-muted-foreground text-xs text-center">
                          VS
                        </span>
                        <div className="flex items-center justify-start px-8 gap-2 flex-1">
                          <p className="text-sm">
                            {match.details.teams.away?.name}
                          </p>
                          {match.details.goals ? (
                            <div className="border-2 border-border w-8 h-8 flex items-center justify-center">
                              <p className="text-sm">
                                {match.details.goals?.away}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="flex items-center gap-2 flex-1">
                        {isAddMatch ? (
                          <input
                            className="w-1/2 border-border text-sm p-1 text-[rgba(255,255,255,.5)] border-[1px] outline-none bg-transparent"
                            type="text"
                            onChange={(e) => {
                              set_api_rounds({
                                ...api_rounds,
                                [match.id]: e.target.value,
                              });
                            }}
                            defaultValue={
                              api_rounds[match.id] ?? isNaN(match.round)
                                ? ""
                                : match.round
                            }
                            name=""
                            id=""
                          />
                        ) : (
                          <p className="text-sm text-muted-foreground">
                            Round {match.round}
                          </p>
                        )}
                      </div>

                      <div className="flex items-center gap-2 flex-1">
                        <p className="text-sm text-muted-foreground">
                          {match.details.fixture?.status?.long ?? "N/A"}
                        </p>
                      </div>

                      <div className="flex items-center gap-4 flex-1">
                        {match.open && !closed_prediction ? (
                          <Badge variant={"success"}>Open</Badge>
                        ) : (
                          <Badge variant={"progress"}>Closed</Badge>
                        )}
                      </div>
                      <div className="flex items-center gap-4 flex-1">
                        <p className="text-sm text-muted-foreground line-clamp-1">
                          {match.id}
                        </p>
                        {isAddMatch ? (
                          <Button
                            disabled={adding_api_matches[match.id] ?? false}
                            size={"sm"}
                            onClick={async () => {
                              try {
                                if (adding_api_matches[match.id]) return;

                                set_adding_api_matches({
                                  ...adding_api_matches,
                                  [match.id]: true,
                                });
                                const credential =
                                  localStorage.getItem("credential");
                                const response = await apiClient.post(
                                  "/match",
                                  {
                                    ...match,
                                    round: api_rounds[match.id] ?? match.round,
                                  },
                                  {
                                    headers: {
                                      Authorization: `bearer ${
                                        credential
                                          ? JSON.parse(credential).accessToken
                                          : ""
                                      }`,
                                    },
                                  }
                                );

                                if (response.data.success) {
                                  toast.success(response.data.message);
                                  add_single_match({
                                    ...match,
                                    round: api_rounds[match.id] ?? match.round,
                                  });

                                  if (is_api_match_search) {
                                    const new_api_search_result =
                                      api_search_results.filter(
                                        (ft) =>
                                          ft.id.toString() !==
                                          match.id.toString()
                                      );
                                    set_api_search_result(
                                      new_api_search_result
                                    );
                                  } else {
                                    const new_api_matches = new_matches.filter(
                                      (ft) =>
                                        ft.id.toString() !== match.id.toString()
                                    );
                                    set_new_matches(new_api_matches);
                                  }
                                } else {
                                  toast.error(
                                    response?.data?.message ??
                                      "OOPS!! Something went wrong!"
                                  );
                                }
                                set_adding_api_matches({
                                  ...adding_api_matches,
                                  [match.id]: false,
                                });
                              } catch (error: any) {
                                set_adding_api_matches({
                                  ...adding_api_matches,
                                  [match.id]: false,
                                });
                                toast.error(
                                  error?.response?.data?.message ??
                                    "OOPS!! Something went wrong!"
                                );
                              }
                            }}
                            variant={"default"}
                          >
                            {adding_api_matches[match.id] ? (
                              <TbLoader
                                size={22}
                                className="mr-1.5 animate-spin"
                              />
                            ) : (
                              "Add Match"
                            )}
                          </Button>
                        ) : (
                          <DropdownMenu>
                            <DropdownMenuTrigger
                              disabled={isDeletingMatch === match?.id}
                              asChild
                            >
                              <div
                                role="button"
                                className={cn("cursor-pointer ml-auto", {
                                  "pointer-events-none opacity-50":
                                    isDeletingMatch === match?.id,
                                })}
                              >
                                <HiOutlineEllipsisVertical className="size-4 text-muted-foreground hover:text-foreground" />
                              </div>
                            </DropdownMenuTrigger>

                            <DropdownMenuContent className="w-auto z-50">
                              <DropdownMenuGroup>
                                <UpdateMatch {...updateMatchProps}>
                                  <p className="relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50">
                                    Update
                                  </p>
                                </UpdateMatch>
                                <DropdownMenuItem
                                  disabled={isDeletingMatch === match?.id}
                                  onSelect={() => {
                                    set_open_delete_confirmation(true);
                                    set_match_to_delete(match);
                                  }}
                                >
                                  Delete
                                </DropdownMenuItem>

                                <DropdownMenuItem
                                  disabled={
                                    isDeletingMatch === match?.id ||
                                    match?.scored
                                  }
                                  onSelect={() => {
                                    if (match?.scored) {
                                    } else {
                                      set_open_score_modal(true);
                                      set_score_match(match);
                                    }
                                  }}
                                >
                                  {match.scored ? "Scored" : "Add match Score"}
                                </DropdownMenuItem>
                              </DropdownMenuGroup>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {(total_matches > itemsPerPage * (currentPage + 1) ||
            currentPage > 0) &&
            total_matches > 0 && (
              <div className="flex items-center justify-end mt-4">
                <div className="flex items-center gap-4">
                  <p className="text-sm text-muted-foreground">
                    Page {currentPage + 1} of{" "}
                    {Math.ceil(total_matches / itemsPerPage)}
                  </p>
                  <Button
                    size={"sm"}
                    variant={"secondary"}
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                  >
                    Prev
                  </Button>
                  <Button
                    size={"sm"}
                    variant={"secondary"}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages - 1}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
        </div>
      </section>
    </div>
  );
}

function AddMatch({
  children,
  handleChange,
  handleAddMatch,
  formData,
  errors,
  isAddingMatch,
  setShowModal,
  showModal,
  last_games,
  handleGameChange,
  get_api_matches,
}: {
  children: ReactNode;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleAddMatch: (e: FormEvent) => void;
  get_api_matches: () => void;
  formData: typeof initialFormValues;
  errors: typeof initialFormValues;
  isAddingMatch: boolean;
  setShowModal: any;
  showModal: boolean;
  last_games: any;
  handleGameChange: (spot: string, index: string, value: string) => void;
}) {
  return (
    <Dialog open={showModal} onOpenChange={(open) => setShowModal(open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[1043px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Add Match</p>
          <Button
            size={"sm"}
            variant={"default"}
            onClick={() => {
              setShowModal(false);
              get_api_matches();
            }}
          >
            Get From API
          </Button>
          {/* <button
            onClick={() => {
              setShowModal(false);
              get_api_matches();
            }}
          ></button> */}

          <DialogClose onClick={() => setShowModal(!showModal)}>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <form onSubmit={handleAddMatch} className="flex flex-col gap-8">
            <div className="flex items-end gap-4">
              <InputField
                name="home"
                type="text"
                label="Choose Home Team"
                placeholder="Type team name"
                value={formData.home}
                onChange={handleChange}
                error={errors.home}
                icon={FaRegKeyboard}
                disabled={isAddingMatch}
                reverseIcon
              />
              <span className="text-sm text-muted-foreground pb-3">VS</span>
              <InputField
                name="away"
                type="text"
                label="Choose Away Team"
                placeholder="Type team name"
                value={formData.away}
                onChange={handleChange}
                error={errors.away}
                icon={FaRegKeyboard}
                disabled={isAddingMatch}
                reverseIcon
              />
            </div>

            <InputField
              name="venue"
              type="text"
              label="Match Venue"
              placeholder="Type match venue"
              value={formData.venue}
              onChange={handleChange}
              error={errors.venue}
              icon={FaRegKeyboard}
              disabled={isAddingMatch}
              reverseIcon
            />

            <div className="flex items-end gap-4">
              <InputField
                name="timestamp"
                type="datetime-local"
                label="Date & Time"
                placeholder="Choose date and time"
                value={formData.timestamp}
                onChange={handleChange}
                error={errors.timestamp}
                icon={RiCalendarTodoLine}
                disabled={isAddingMatch}
              />

              <InputField
                name="round"
                type="number"
                label="Round"
                placeholder="Type match round"
                value={formData.round.toString()}
                onChange={handleChange}
                // error={errors.round.toString()}
                icon={FaRegKeyboard}
                disabled={isAddingMatch}
                reverseIcon
              />

              {/* <div className="relative h-12 w-full">
                <select
                  name="round"
                  id="round"
                  value={formData.round}
                  onChange={handleChange}
                  className="size-full border border-border/30 outline-none rounded-xl text-base px-4 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground"
                  disabled={isAddingMatch}
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      Round {index + 1}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>

            <div className="flex gap-[50px]">
              <div className="flex flex-col items-start gap-1 w-full">
                <div className="flex items-center gap-10 justify-between w-full">
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-sm text-muted-foreground capitalize"
                    >
                      Home Team's Last 5 Games
                    </label>
                    <div className="mt-3 flex flex-col gap-3">
                      {Array.from({ length: 5 }).map((_, key) => {
                        return (
                          <div
                            key={key}
                            className="flex items-center justify-between w-full"
                          >
                            <p>Game {key + 1}</p>
                            <div className="relative h-12">
                              <select
                                name="score"
                                id="score"
                                defaultValue={last_games.home?.[key.toString()]}
                                onChange={(e) => {
                                  handleGameChange(
                                    "home",
                                    key.toString(),
                                    e.target.value
                                  );
                                }}
                                className="size-full border border-border/30 outline-none rounded-xl text-base bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground px-10"
                                disabled={isAddingMatch}
                              >
                                <option>Choose</option>
                                <option value={"Win"}>Win</option>
                                <option value={"Lose"}>Lose</option>
                                <option value={"Draw"}>Draw</option>
                              </select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-sm text-muted-foreground capitalize"
                    >
                      Away Team's Last 5 Games
                    </label>
                    <div className="mt-3 flex flex-col gap-3">
                      {Array.from({ length: 5 }).map((_, key) => {
                        return (
                          <div
                            key={key}
                            className="flex items-center justify-between w-full"
                          >
                            <p>Game {key + 1}</p>
                            <div className="relative h-12">
                              <select
                                name="score"
                                id="score"
                                defaultValue={last_games.away?.[key.toString()]}
                                onChange={(e) => {
                                  handleGameChange(
                                    "away",
                                    key.toString(),
                                    e.target.value
                                  );
                                }}
                                className="size-full border border-border/30 outline-none rounded-xl text-base bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground px-10"
                                disabled={isAddingMatch}
                              >
                                <option>Choose</option>
                                <option value={"Win"}>Win</option>
                                <option value={"Lose"}>Lose</option>
                                <option value={"Draw"}>Draw</option>
                              </select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogHeader>
        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose disabled={isAddingMatch} asChild>
            <Button variant={"secondary"}>Cancel</Button>
          </DialogClose>

          <Button
            disabled={isAddingMatch}
            type="submit"
            onClick={handleAddMatch}
          >
            {isAddingMatch ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" /> Please
                wait...
              </>
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

interface UpdateMatchProps {
  children: ReactNode;
  matchId: any;
  showUpdateModal: boolean;
  setShowUpdateModal: (show: boolean) => void;
  updateMatchData: ({
    updateData,
    matchId,
  }: {
    updateData: any;
    matchId: string;
  }) => void;
  isUpdating: boolean;
}

interface FormData {
  home: string;
  away: string;
  venue: string;
  timestamp: string;
  round: string;
}

export function UpdateMatch({
  children,
  matchId,
  showUpdateModal,
  setShowUpdateModal,
  updateMatchData,
  isUpdating,
}: UpdateMatchProps) {
  const match = matchId[0];
  // console.log(match, "here");

  const date = new Date(match?.details?.fixture?.date);

  // Format the current date and time to YYYY-MM-DDTHH:MM
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}`;

  const initialUpdateFormValue: FormData = {
    home: match?.details?.teams?.home?.name || "",
    away: match?.details?.teams?.away?.name || "",
    venue: match?.details?.fixture?.venue?.name || "",
    timestamp: formattedDateTime,
    round: match?.round?.toString() || "1",
  };

  const [formData, setFormData] = useState<FormData>(initialUpdateFormValue);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [last_games, set_last_games] = useState<any>({});

  useEffect(() => {
    if (showUpdateModal) {
      setFormData(initialUpdateFormValue);
      if (match?.details?.last_games) {
        const __last_games = match?.details.last_games;
        const construct = {
          home: __last_games.home?.reduce(
            (acc: any, value: any, index: any) => {
              acc[index] = value;
              return acc;
            },
            {}
          ),
          away: __last_games.away?.reduce(
            (acc: any, value: any, index: any) => {
              acc[index] = value;
              return acc;
            },
            {}
          ),
        };

        // console.log(construct);

        set_last_games(construct);
      }
      setErrors({});
    }
  }, [showUpdateModal, match]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleGameChange = (spot: string, index: string, value: string) => {
    set_last_games({
      ...last_games,
      [spot]: {
        ...last_games[spot],
        [index]: value.toString().toLowerCase() === "choose" ? "" : value,
      },
    });
  };

  const validateInputs = (data: FormData): boolean => {
    const newErrors: Partial<FormData> = {};
    let hasError = false;

    if (!data.home) {
      newErrors.home = "Home team is required";
      hasError = true;
    }
    if (!data.away) {
      newErrors.away = "Away team is required";
      hasError = true;
    }
    if (!data.venue) {
      newErrors.venue = "Venue is required";
      hasError = true;
    }
    if (!data.timestamp) {
      newErrors.timestamp = "Timestamp is required";
      hasError = true;
    }
    if (parseInt(data.round) <= 0) {
      newErrors.round = "Round must be greater than 0";
      hasError = true;
    }

    setErrors(newErrors);
    return !hasError;
  };

  const handleUpdateMatch = (e: FormEvent) => {
    e.preventDefault();
    // console.log(formData);
    // console.log(new Date(formData.timestamp).toISOString());
    // return;

    if (!last_games.home || !last_games.away)
      return toast.error("Last games not set");

    const home_last_games = Object.values(last_games.home);
    if (home_last_games.length < 5 || home_last_games.includes(""))
      return toast.error("Incomplete home last games");

    const away_last_games = Object.values(last_games.away);
    if (away_last_games.length < 5 || away_last_games.includes(""))
      return toast.error("Incomplete away last games");

    if (validateInputs(formData)) {
      // const newId = generateRandomNumber(6);

      const updateData = {
        // id: newId,
        round: Number(formData.round),
        details: {
          ...match.details,
          fixture: {
            ...match.details.fixture,
            id: match.id,
            date: new Date(formData.timestamp).toISOString(),
            venue: {
              name: formData.venue,
            },
          },
          last_games: {
            home: home_last_games,
            away: away_last_games,
          },
          teams: {
            home: {
              ...match.details.teams.home,
              name: formData.home,
            },
            away: {
              ...match.details.teams.away,

              name: formData.away,
            },
          },
        },
      };

      // console.log(updateData.details.fixture.date);
      updateMatchData({ updateData, matchId: match?.id });
    }
  };

  return (
    <Dialog open={showUpdateModal} onOpenChange={setShowUpdateModal}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[1043px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Update Match - {match?.id}</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <form onSubmit={handleUpdateMatch} className="flex flex-col gap-8">
            <div className="flex items-end gap-4">
              <InputField
                name="home"
                type="text"
                label="Home Team"
                placeholder="Type team name"
                value={formData.home}
                onChange={handleChange}
                error={errors.home}
                icon={FaRegKeyboard}
                disabled={isUpdating}
                reverseIcon
              />
              <span className="text-sm text-muted-foreground pb-3">VS</span>
              <InputField
                name="away"
                type="text"
                label="Away Team"
                placeholder="Type team name"
                value={formData.away}
                onChange={handleChange}
                error={errors.away}
                icon={FaRegKeyboard}
                disabled={isUpdating}
                reverseIcon
              />
            </div>

            <InputField
              name="venue"
              type="text"
              label="Match Venue"
              placeholder="Type match venue"
              value={formData.venue}
              onChange={handleChange}
              error={errors.venue}
              icon={FaRegKeyboard}
              disabled={isUpdating}
              reverseIcon
            />

            <div className="flex items-end gap-4">
              <InputField
                name="timestamp"
                type="datetime-local"
                label="Date & Time"
                placeholder="Choose date and time"
                value={formData.timestamp}
                onChange={handleChange}
                error={errors.timestamp}
                icon={RiCalendarTodoLine}
                disabled={isUpdating}
              />

              <InputField
                name="round"
                type="text"
                label="Round"
                placeholder="Type match round"
                value={formData.round}
                onChange={handleChange}
                error={errors.round}
                icon={FaRegKeyboard}
                disabled={isUpdating}
                reverseIcon
              />
              {/* <div className="relative h-12 w-full">
                <select
                  name="round"
                  id="round"
                  value={formData.round}
                  onChange={handleChange}
                  className="size-full border border-border/30 outline-none rounded-xl text-base px-4 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground"
                  disabled={isUpdating}
                >
                  {Array.from({ length: 10 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      Round {index + 1}
                    </option>
                  ))}
                </select>
                {errors.round && (
                  <p className="text-red-500 text-xs mt-1">{errors.round}</p>
                )}
              </div> */}
            </div>

            <div className="flex gap-[50px]">
              <div className="flex flex-col items-start gap-1 w-full">
                <div className="flex items-center gap-10 justify-between w-full">
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-sm text-muted-foreground capitalize"
                    >
                      Home Team's Last 5 Games
                    </label>
                    <div className="mt-3 flex flex-col gap-3">
                      {Array.from({ length: 5 }).map((_, key) => {
                        return (
                          <div
                            key={key}
                            className="flex items-center justify-between w-full"
                          >
                            <p>Game {key + 1}</p>
                            <div className="relative h-12">
                              <select
                                name="score"
                                id="score"
                                value={last_games.home?.[key.toString()]}
                                onChange={(e) => {
                                  handleGameChange(
                                    "home",
                                    key.toString(),
                                    e.target.value
                                  );
                                }}
                                className="size-full border border-border/30 outline-none rounded-xl text-base bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground px-10"
                                disabled={isUpdating}
                              >
                                <option>Choose</option>
                                <option value={"Win"}>Win</option>
                                <option value={"Lose"}>Lose</option>
                                <option value={"Draw"}>Draw</option>
                              </select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor=""
                      className="text-sm text-muted-foreground capitalize"
                    >
                      Away Team's Last 5 Games
                    </label>
                    <div className="mt-3 flex flex-col gap-3">
                      {Array.from({ length: 5 }).map((_, key) => {
                        return (
                          <div
                            key={key}
                            className="flex items-center justify-between w-full"
                          >
                            <p>Game {key + 1}</p>
                            <div className="relative h-12">
                              <select
                                name="score"
                                id="score"
                                value={last_games.away?.[key.toString()]}
                                onChange={(e) => {
                                  handleGameChange(
                                    "away",
                                    key.toString(),
                                    e.target.value
                                  );
                                }}
                                className="size-full border border-border/30 outline-none rounded-xl text-base bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground px-10"
                                disabled={isUpdating}
                              >
                                <option>Choose</option>
                                <option value={"Win"}>Win</option>
                                <option value={"Lose"}>Lose</option>
                                <option value={"Draw"}>Draw</option>
                              </select>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </DialogHeader>
        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button variant="secondary" disabled={isUpdating}>
              Cancel
            </Button>
          </DialogClose>

          <Button
            disabled={isUpdating}
            type="submit"
            onClick={handleUpdateMatch}
          >
            {isUpdating ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                Updating...
              </>
            ) : (
              "Update Match"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

interface IAddScoreModal {
  open: boolean;
  onOpenChange: () => void;
  success_callback: () => void;
  match: TMatch | null;
}
const AddScoreModal = ({
  match,
  onOpenChange,
  open,
  success_callback,
}: IAddScoreModal) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [goals, setGoals] = useState<any>({});
  const handleSubmit = async () => {
    try {
      const credential = localStorage.getItem("credential");
      if (loading) return;
      if (!match?.id) return;
      setLoading(true);
      const response = await apiClient.post(
        "/score",
        {
          matchId: match.id,
          goals,
        },

        {
          headers: {
            Authorization: `bearer ${
              credential ? JSON.parse(credential).accessToken : ""
            }`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Match scored successfully");
        setGoals({});
        success_callback();
      } else {
        toast.error(response.data.message ?? "OOPS!! Something went wrong!");
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ?? "OOPS!! Something went wrong!"
      );
    }
    setLoading(false);
  };
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger asChild>{children}</DialogTrigger> */}
      <DialogContent className="max-w-[1043px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Score Match - {match?.id}</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <form onSubmit={handleSubmit} className="flex flex-col gap-8">
            <div className="flex items-end gap-4">
              <InputField
                name="home"
                type="text"
                label={`${match?.details?.teams?.home?.name} Score`}
                placeholder="Type Home Score"
                value={goals.home ?? ""}
                // onChange={() => {}}
                onChange={(e) => setGoals({ ...goals, home: e.target.value })}
                icon={FaRegKeyboard}
                disabled={loading}
                reverseIcon
              />
              <span className="text-sm text-muted-foreground pb-3">VS</span>
              <InputField
                name="away"
                type="text"
                label={`${match?.details?.teams?.away?.name} Score`}
                placeholder="Type team score"
                value={goals.away ?? ""}
                onChange={(e) => setGoals({ ...goals, away: e.target.value })}
                icon={FaRegKeyboard}
                disabled={loading}
                reverseIcon
              />
            </div>
          </form>
        </DialogHeader>
        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button variant="secondary" disabled={loading}>
              Cancel
            </Button>
          </DialogClose>

          <Button disabled={loading} type="submit" onClick={handleSubmit}>
            {loading ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                Scoring...
              </>
            ) : (
              "Update Match"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

interface DeleteProps {
  open: boolean;
  onOpenChange: () => void;
  handle_submit: (match_id: string) => void;
  match: TMatch | null;
  loading: boolean;
}
const DeleteConfirmation = ({
  match,
  onOpenChange,
  open,
  handle_submit,
  loading,
}: DeleteProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {/* <DialogTrigger asChild>{children}</DialogTrigger> */}
      <DialogContent className="max-w-[1043px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Score Match - {match?.id}</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <h4>Are you sure you want to delete match?</h4>
          <div className="my-10 flex items-center justify-around">
            <h3>{match?.details?.teams?.home?.name}</h3>
            <h3>-</h3>
            <h3>{match?.details?.teams?.away?.name}</h3>
          </div>
        </DialogHeader>
        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button variant="secondary" disabled={loading}>
              Cancel
            </Button>
          </DialogClose>

          <Button
            disabled={loading}
            type="submit"
            variant="destructive"
            onClick={() => {
              if (match) {
                handle_submit(match!.id);
              }
            }}
          >
            {loading ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                Scoring...
              </>
            ) : (
              "Delete Match"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
