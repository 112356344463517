import { useState } from "react";
import { useFetchCurrentUser } from "./useFetchCurrentUser";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { useToast } from "./useToast";
import {
  getPlayerData,
  getPlayers,
  getPlayerStats,
  searchPlayer,
} from "pages/dashboard/controller/Player.Controller";

export const usePlayers = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { accessToken } = useFetchCurrentUser();
  const { showToast } = useToast();

  const [players, setPlayers] = useState<TCredential[]>([]);
  const [total_players, set_total_players] = useState(0);
  const [currentPlayerData, setCurrentPlayerData] = useState<
    TCredential | undefined
  >();
  const [currentPlayerStats, setCurrentPlayerStats] = useState<any>();
  const [isFetchingCurrentUser, setIsFetchingCurrentUser] = useState(true);
  const [isFetchingPlayers, setIsFetchingPlayers] = useState(true);

  const getAllPlayers = ({ size, page }: { size: number; page: number }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    setIsFetchingPlayers(true);

    dispatch(getPlayers({ accessToken, size, page }))
      .then((data) => {
        if (data.payload.success) {
          setPlayers(data.payload.data?.rows);
          set_total_players(data.payload.data?.count);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsFetchingPlayers(false);
      });
  };

  const getCurrentPlayerData = ({ userId }: { userId: string }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }
    dispatch(getPlayerData({ accessToken, userId }))
      .then((data) => {
        if (data.payload.success) {
          setCurrentPlayerData(data.payload.data);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      });
  };

  const getCurrentPlayerStats = ({ userId }: { userId: string }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }

    dispatch(getPlayerStats({ accessToken, userId }))
      .then((data) => {
        if (data.payload.success) {
          setCurrentPlayerStats(data.payload.data);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsFetchingCurrentUser(false);
      });
  };

  const searchPlayerByUsername = ({ username }: { username: string }) => {
    if (!accessToken) {
      showToast({ message: "Credential not found", type: "error" });
      return;
    }

    setIsFetchingPlayers(true);

    dispatch(searchPlayer({ accessToken, username }))
      .then((data) => {
        if (data.payload.success) {
          console.log(data.payload.data);
          setPlayers(data.payload.data || []);
        } else {
          showToast({ message: data?.payload?.message, type: "error" });
        }
      })
      .catch((error) => {
        showToast({
          message: error?.message || "Could not fetch user data",
          type: "error",
        });
      })
      .finally(() => {
        setIsFetchingPlayers(false);
      });
  };

  return {
    getAllPlayers,
    getCurrentPlayerData,
    getCurrentPlayerStats,
    searchPlayerByUsername,
    players,
    isFetchingCurrentUser,
    isFetchingPlayers,
    currentPlayerData,
    currentPlayerStats,
    total_players,
  };
};
