import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function Confirmation() {
  const navigate = useNavigate();

  const { email } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/new-password");
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className="size-full">
      <div className="flex flex-col items-center size-full max-w-[484px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">Check Your Email</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            We sent a password reset link to{" "}
            <b className="!text-white !opacity-100">{email}</b>
          </p>
        </div>
      </div>
    </div>
  );
}
