import { BiExpandAlt } from "react-icons/bi";
import { Button, buttonVariants } from "../ui/Button";
import { FaRegUserCircle } from "react-icons/fa";
import { IoFootballOutline } from "react-icons/io5";
import { LiaUsersCogSolid } from "react-icons/lia";
import { IoMdLogOut } from "react-icons/io";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { assets } from "assets";
import AddAdmin from "./AddAdmin";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { logout } from "state/slices/authSlice";
import { useLocalStorage } from "hooks/useLocalStorage";
import { apiClient, initials } from "utils";
import { useState } from "react";
import toast from "react-hot-toast";

export default function Sidebar() {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { delFn, getFn } = useLocalStorage("credential");

  const credential: TCredential | undefined = getFn();

  const handleLogout = () => {
    dispatch(logout());
    delFn();
    navigate("/sign-in", { replace: true });
  };

  const routes = [
    {
      label: "Players",
      path: "/players",
      icon: FaRegUserCircle,
    },
    {
      label: "Matches",
      path: "/matches",
      icon: IoFootballOutline,
    },
  ];

  const [loading, setLoading] = useState(false);

  const downloadZip = async (): Promise<void> => {
    try {
      setLoading(true);
      // Fetch the zip file from the backend
      const accessToken = localStorage.getItem("credential")
        ? JSON.parse(localStorage.getItem("credential")!).accessToken
        : null;
      const response = await apiClient.get("/export-data", {
        responseType: "blob", // Important to handle binary data
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // Create a Blob URL
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "files.zip"); // File name

      // Append the link to the document body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link element
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error downloading zip");
      // console.error("Error downloading zip:", error);
    }

    setLoading(false);
  };

  return (
    <aside className="max-w-[300px] w-full flex flex-col">
      <AddAdmin>
        <header className="p-4 border-b border-border/20 w-full">
          <div className="flex items-center rounded-xl bg-foreground/5 hover:bg-secondary transition p-2 border border-border/20 ring-1 ring-black cursor-pointer">
            <div className="flex items-center justify-center size-10 rounded-lg border border-border/20 ring-1 ring-black [background:_radial-gradient(103.31%_110%_at_43.75%_21.25%,_rgba(255,_255,_255,_0)_0%,_rgba(255,_255,_255,_0.123)_55.28%,_rgba(255,_255,_255,_0.246)_100%)]">
              <h5 className="font-medium">
                {initials(`${credential?.firstname} ${credential?.lastname}`)}
              </h5>
            </div>

            <div className="flex items-center flex-1 pl-2 pr-1">
              <div className="flex flex-col flex-1">
                <p className="text-sm text-foreground font-medium line-clamp-1">
                  {credential?.firstname} {credential?.lastname}
                </p>
                <span className="text-xs text-muted-foreground/80 font-medium">
                  {credential?.level === 2
                    ? "Super Administrator"
                    : credential?.level === 1
                    ? "Administrator"
                    : "Test Account"}
                </span>
              </div>

              <BiExpandAlt className="size-4 ml-auto" />
            </div>
          </div>
        </header>
      </AddAdmin>

      <div className="p-4 flex-1 flex flex-col">
        <ul className="flex flex-col gap-1 flex-1">
          {routes.map((route) => (
            <NavLink
              key={route.path}
              to={route.path}
              className={({ isActive }) =>
                buttonVariants({
                  variant: isActive ? "secondary" : "ghost",
                  className: "!justify-start !gap-3 !px-3",
                })
              }
            >
              <route.icon className="size-5" />
              <span className="text-sm font-medium">{route.label}</span>
            </NavLink>
          ))}
        </ul>

        <button
          disabled={loading}
          className={buttonVariants({
            variant: "secondary",
            className: "!justify-start !gap-3 !px-3",
          })}
          onClick={async () => {
            await downloadZip();
          }}
        >
          <AiOutlineCloudDownload />
          {loading ? "Processing..." : "export data"}
        </button>

        <ul className="flex flex-col gap-1 mt-auto">
          {/* <NavLink
            to="/settings"
            className={({ isActive }) =>
              buttonVariants({
                variant: isActive ? "secondary" : "ghost",
                className: "!justify-start !gap-3 !px-3",
              })
            }
          >
            <LiaUsersCogSolid className="size-5" />
            <span className="text-sm font-medium">Settings</span>
          </NavLink> */}
          <Button
            onClick={handleLogout}
            variant={"ghost"}
            className="justify-start gap-3 px-3"
          >
            <IoMdLogOut className="size-5" />
            <span className="text-sm font-medium">Sign Out</span>
          </Button>
        </ul>
      </div>

      <footer className="p-4 border-t border-border/20 w-full">
        <img src={assets.images.logoIcon} alt="" className="w-[200px]" />
      </footer>
    </aside>
  );
}
