import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { useLocalStorage } from "hooks/useLocalStorage";
import { apiClient } from "utils";

export const invitePlayer = createAsyncThunk(
  "auth/invitePlayer",
  async (
    { email, accessToken }: { email: string; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        "auth/invite-admin",
        { email: email },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async (userData: TSignUpPayload, { rejectWithValue }) => {
    const formField = {
      username: userData.username,
      email: userData.email,
      firstname: userData.firstname,
      lastname: userData.lastname,
      password: userData.lastname,
    };

    try {
      const response = await apiClient.post(`/auth/sign-up`, formField);
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ otp }: TTVerifyEmailPayload, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/auth/verify/${otp}`);
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (
    { email, password, rememberDevice }: TSignInPayload,
    { rejectWithValue }
  ) => {
    const { setFn, delFn } = useLocalStorage("credential");

    try {
      const response = await apiClient.post(`/auth/sign-in`, {
        email,
        password,
      });

      if (response.data) {
        if (rememberDevice === true) {
          setFn(response.data.data);
        } else {
          delFn();
        }
      }

      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ newPassword }: TResetPasswordPayload, { rejectWithValue }) => {
    try {
      const accessToken = JSON.parse(
        localStorage.getItem("credential") ?? JSON.stringify({})
      )?.accessToken;
      const response = await apiClient.post(
        "/auth/reset-password",
        {
          newPassword,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const requestOtp = createAsyncThunk(
  "auth/requestOtp",
  async ({ email }: TRequestOTP, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("/auth/send-verification", {
        email,
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);
