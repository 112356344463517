import { assets } from "assets";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function AuthLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getFn } = useLocalStorage("credential");
  const credential = getFn();

  useEffect(() => {
    if (credential !== undefined && location.pathname === "/") {
      navigate("/players", { replace: true });
    }
  }, [credential, navigate]);

  return (
    <div className="min-h-dvh w-full antialiased flex flex-1 relative p-4 gap-5">
      <div className="hidden lg:flex flex-1 w-[55%] lg:w-[45%] flex-col justify-between relative overflow-clip p-6">
        <div className="-z-10 absolute top-0 left-0 size-full">
          <img
            src={assets.images.authBg}
            alt=""
            className="object-cover w-[1177px] h-[1232px]"
            loading="lazy"
          />
        </div>
      </div>

      <div className="md:px-4 py-6 flex w-full lg:w-[45%]">
        <Outlet />
      </div>
    </div>
  );
}
