import InputField from "common/components/shared/InputeField";
import { Button } from "common/components/ui/Button";
import { FormEvent, useState } from "react";
import { TbLoader } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state/store";
import { verifyEmail } from "../controller/Auth.Controller";
import toast from "react-hot-toast";
import CustomToast from "common/components/shared/CustomToast";

export default function VerifyOTP() {
  const { email } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  // navigate(`/verify-otp/${formData.email}?from=request-password`);
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get("from");

  const initialFormValues = {
    otp: "",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [errors, setErrors] = useState(initialFormValues);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === "string") {
      setFormData((prev) => ({ ...prev, otp: e }));
      setErrors((prev) => ({ ...prev, otp: "" }));
    } else {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.otp) {
      setIsLoading(true);

      dispatch(verifyEmail({ otp: formData.otp }))
        .then((data) => {
          console.log(data.payload);
          if (
            data.payload.data?.level?.toString() === "0" ||
            !data.payload.data?.level
          ) {
            toast.custom(
              (t) => (
                <CustomToast toast={t} message="UNAUTHORIZED" type="error" />
              ),
              {
                duration: 5000,
              }
            );

            return;
          }

          if (data.payload.success) {
            localStorage.setItem(
              "credential",
              JSON.stringify(data.payload.data)
            );
            if (from === "request-password") {
              navigate("/new-password");
            } else if (from === "sign-up") {
              navigate("/sign-in");
            }
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  message="Email verified successfully"
                  type="success"
                />
              ),
              {
                duration: 5000,
              }
            );
          } else {
            toast.custom(
              (t) => (
                <CustomToast
                  toast={t}
                  message={data.payload.message}
                  type="error"
                />
              ),
              {
                duration: 5000,
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
          toast.custom(
            (t) => (
              <CustomToast
                toast={t}
                message={error?.message || "Could not sign up"}
                type="error"
              />
            ),
            {
              duration: 5000,
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="size-full relative">
      <div className="flex flex-col items-center size-full max-w-[550px] mx-auto">
        <div className="flex flex-col gap-4 text-center">
          <h4 className="!text-[32px]">Verify Email</h4>
          <p className="text-base md:text-lg font-normal opacity-60">
            We’ve sent an OTP to <b>{email}</b>. Please check your inbox and
            click the link to verify your email.
          </p>
        </div>

        <div className="w-full mt-6 mb-8">
          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-3 md:gap-4"
          >
            <InputField
              name="email"
              type="email"
              placeholder="johndoe@gmail.com"
              value={formData.otp}
              onChange={handleChange}
              error={errors.otp}
              inputType="otp"
              disabled={isLoading}
            />

            <Button
              type="submit"
              variant={"secondary"}
              size={"lg"}
              disabled={isLoading}
              className="w-full mt-4"
            >
              {isLoading ? (
                <>
                  <TbLoader size={22} className="mr-1.5 animate-spin" /> Please
                  wait...
                </>
              ) : (
                "Verify OTP"
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
