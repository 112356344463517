import { assets } from "assets";
import { Button } from "common/components/ui/Button";
import { FaRegUserCircle } from "react-icons/fa";
import { RiArrowRightSLine } from "react-icons/ri";
import { MdOutlineIosShare } from "react-icons/md";

import { useEffect, useState } from "react";
import { cn, getCountryDetails } from "utils";
import Statistics from "common/components/shared/Statistics";
import PersonalDetails from "common/components/shared/PersonalDetails";
import { useParams } from "react-router-dom";
import { initials } from "utils";
import { Skeleton } from "common/components/ui/Skeleton";
import { usePlayers } from "hooks/usePlayers";

const tabs = [
  {
    label: "Statistics",
    value: "stats",
  },
  {
    label: "Player Information",
    value: "info",
  },
];

export default function PlayerInfo() {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const {
    getCurrentPlayerData,
    getCurrentPlayerStats,
    isFetchingCurrentUser,
    currentPlayerData,
    currentPlayerStats,
  } = usePlayers();

  useEffect(() => {
    getCurrentPlayerData({ userId: id! });
  }, [id]);

  useEffect(() => {
    getCurrentPlayerStats({ userId: id! });
  }, [currentPlayerData]);

  return (
    <div className="flex flex-col overflow-y-auto">
      <header className="border-b p-4 border-border/30 h-[74px] flex items-center gap-3">
        <div className="flex items-center h-full gap-3 text-muted-foreground">
          <FaRegUserCircle className="size-4" />
          <p>Players</p>
        </div>
        <div className="flex items-center h-full gap-3 text-muted-foreground">
          <RiArrowRightSLine className="size-4" />
          <p>Player Information</p>
        </div>
      </header>

      <section className="p-4 pb-10">
        {isFetchingCurrentUser ? (
          <Skeleton className="w-full h-[200px] rounded-xl" />
        ) : (
          <div className="w-full h-[200px] rounded-xl border border-border/30 relative overflow-hidden">
            <img
              src={assets.images.profileBanner}
              alt="profile banner"
              className="size-full object-cover"
            />
          </div>
        )}

        <div className="flex items-end h-40 px-8 relative -mt-12">
          <div className="flex items-end gap-6 flex-1">
            <div className="bg-secondary size-40 rounded-lg shadow">
              {isFetchingCurrentUser ? (
                <Skeleton className="size-full rounded-[inherit]" />
              ) : (
                <div className="flex items-center justify-center size-full rounded-[inherit] border border-border/20 ring-1 ring-black/50 [background:_radial-gradient(103.31%_110%_at_43.75%_21.25%,_rgba(255,_255,_255,_0)_0%,_rgba(255,_255,_255,_0.123)_55.28%,_rgba(255,_255,_255,_0.246)_100%)]">
                  <h2>
                    {initials(
                      `${currentPlayerData?.firstname} ${currentPlayerData?.lastname}`
                    )}
                  </h2>
                </div>
              )}
            </div>

            <div className="flex-1 h-24 w-full flex justify-between items-start">
              <div className="flex flex-col gap-2">
                {isFetchingCurrentUser ? (
                  <Skeleton className="h-5 w-44 rounded-md" />
                ) : (
                  <h3 className="text-[28px] leading-none">
                    {currentPlayerData?.firstname} {currentPlayerData?.lastname}
                  </h3>
                )}
                {isFetchingCurrentUser ? (
                  <div className="flex items-center gap-3">
                    <Skeleton className="rounded-full size-5" />
                    <Skeleton className="h-3 w-16 rounded-md" />
                  </div>
                ) : (
                  <p className="text-muted-foreground text-base flex items-center gap-3">
                    {currentPlayerData?.nationality && (
                      <span className="text-xl">
                        {
                          getCountryDetails(
                            String(currentPlayerData?.nationality)
                          )?.emoji
                        }
                      </span>
                    )}
                    <span>
                      {currentPlayerData?.firstname}{" "}
                      {currentPlayerData?.lastname}
                    </span>
                  </p>
                )}
              </div>

              {/* {isFetchingCurrentUser ? (
                <Skeleton className="h-10 w-24 rounded-md" />
              ) : (
                <Button variant={"secondary"}>
                  <MdOutlineIosShare className="size-5 mr-2" />
                  <span>Share</span>
                </Button>
              )} */}
            </div>
          </div>
        </div>

        <div className="w-full mt-8 border-b border-border/10 px-4 flex">
          {tabs.map((tab) => (
            <div
              role="button"
              key={tab.value}
              className={cn(
                "h-14 px-4 flex items-center justify-center relative cursor-pointer text-muted-foreground",
                {
                  "text-foreground": activeTab === tab.value,
                }
              )}
              onClick={() => setActiveTab(tab.value)}
            >
              <p>{tab.label}</p>
              {activeTab === tab.value && (
                <span className="absolute bottom-0 left-0 h-[2px] w-full bg-foreground" />
              )}
            </div>
          ))}
        </div>

        {activeTab === "stats" ? (
          <Statistics
            isLoading={isFetchingCurrentUser}
            {...currentPlayerStats}
          />
        ) : (
          <PersonalDetails
            isLoading={isFetchingCurrentUser}
            {...currentPlayerData}
          />
        )}
      </section>
    </div>
  );
}
