import OTPInput from "react-otp-input";
import { cn } from "utils";

const InputField = (props: TInputeField) => {
  const handleOTPChange = (otp: string) => {
    props.onChange(otp);
  };

  return (
    <div className="flex flex-col items-start gap-1 w-full">
      {props.label !== "" && (
        <label
          htmlFor={props.name}
          className="text-sm text-muted-foreground capitalize"
        >
          {props.label}
        </label>
      )}

      <div
        className={cn("relative h-12 w-full", {
          "opacity-50 cursor-not-allowed": props.disabled,
        })}
      >
        {props.inputType === "otp" ? (
          <div className="mx-auto w-full flex items-center justify-center">
            <OTPInput
              value={props.value}
              onChange={handleOTPChange}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(inputProps: any) => (
                <input
                  name={props.name}
                  {...inputProps}
                  className={cn(
                    "outline-none border rounded-md text-2xl h-14 min-w-14 mx-2 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed",
                    {
                      "border-[#EF4444]": props.error,
                    }
                  )}
                  disabled={props.disabled}
                />
              )}
            />
          </div>
        ) : (
          <input
            disabled={props.disabled}
            name={props.name}
            type={props.type || "text"}
            placeholder={props.placeholder}
            autoComplete="off"
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            className={cn(
              "size-full border border-border/30 outline-none rounded-xl text-base px-5 pr-10 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground",
              {
                "border-[#EF4444]": props.error,
                "pl-12 pr-5": props.reverseIcon,
              }
            )}
          />
        )}

        {props.icon && props.inputType !== "otp" && (
          <props.icon
            size={22}
            className={cn(
              "absolute top-1/2 -translate-y-1/2 right-4 opacity-60 text-foreground/50",
              {
                "cursor-pointer": props.type === "password",
                "cursor-not-allowed": props.disabled,
                "left-4 right-auto": props.reverseIcon,
              }
            )}
            onClick={props.onIconClick}
          />
        )}
      </div>

      {props.error && (
        <span className="text-xs text-[#EF4444]">{props.error}</span>
      )}
    </div>
  );
};

export default InputField;
