import { FormEvent, ReactNode, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, buttonVariants } from "../ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";
import { X } from "lucide-react";
import { useMatch } from "hooks/useMatch";
import { setFilter } from "state/slices/appSlice";
import InputField from "./InputeField";

export default function FilterMatch({
  children,
  onFilterChange,
  is_api_match,
  on_apply,
  on_reset,
}: {
  children: ReactNode;
  is_api_match: boolean;
  on_reset: () => void;
  on_apply: (value: string) => void;
  onFilterChange?: (filter: any) => void;
}) {
  const dispatch = useDispatch();
  const storeFilter = useSelector((state: any) => state.app.filter);
  const [filterState, setFilterState] = useState({
    round: storeFilter.round,
    prediction: storeFilter.prediction,
  });

  const { getAllMatches } = useMatch();

  useEffect(() => {
    setFilterState(storeFilter);
  }, [storeFilter]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilterState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReset = () => {
    const resetState: any = {
      round: "all",
      prediction: "all",
    };
    setFilterState(resetState);
    dispatch(setFilter(resetState));
    if (onFilterChange) {
      onFilterChange(resetState);
    }
    getAllMatches(resetState);
  };

  const handleSubmit = () => {
    dispatch(setFilter(filterState));
    if (onFilterChange) {
      onFilterChange(filterState);
    }
    // getAllMatches({
    //   round: filterState.round,
    //   status: filterState.prediction,
    //   page: "0",
    //   size: "100",
    // });
  };

  const [date_filter, set_date_filter] = useState<string>("");

  return (
    <Dialog
      onOpenChange={(val) => {
        if (!val) {
          set_date_filter("");
        }
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[417px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Filter</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          {is_api_match ? (
            <InputField
              onChange={(e) => {
                set_date_filter(e.target.value);
              }}
              value={date_filter}
              name="timestamp"
              type="datetime-local"
              label="Date & Time"
              placeholder="Choose date and time"
            />
          ) : (
            <div className="flex flex-col gap-8">
              <InputField
                name="round"
                type="number"
                label="Round Number"
                placeholder="Type match round"
                value={filterState.round}
                onChange={handleChange}
                // error={errors.round.toString()}
                // icon={FaRegKeyboard}
                // disabled={isAddingMatch}
                // reverseIcon
              />
              {/* <div className="flex flex-col items-start gap-1 w-full">
                <label
                  htmlFor="round"
                  className="text-sm text-muted-foreground capitalize"
                >
                  Round Number
                </label>
                <div className="relative h-12 w-full">
                  <select
                    name="round"
                    id="round"
                    value={filterState.round}
                    onChange={handleChange}
                    className="size-full border border-border/30 outline-none rounded-xl text-base px-4 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground"
                  >
                    <option value="all">All Rounds</option>
                    {Array.from({ length: 10 }, (_, index) => (
                      <option key={index + 1} value={(index + 1).toString()}>
                        Round {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              </div> */}

              <div className="flex flex-col items-start gap-1 w-full">
                <label
                  htmlFor="prediction"
                  className="text-sm text-muted-foreground capitalize"
                >
                  Prediction Status
                </label>
                <div className="relative h-12 w-full">
                  <select
                    name="prediction"
                    id="prediction"
                    value={filterState.prediction}
                    onChange={handleChange}
                    className="size-full border border-border/30 outline-none rounded-xl text-base px-4 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground capitalize"
                  >
                    <option value="all">All</option>
                    <option value="true">Open</option>
                    <option value="false">Closed</option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </DialogHeader>

        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button
              variant={"secondary"}
              type="button"
              onClick={() => {
                if (is_api_match) {
                  on_reset();
                } else {
                  handleReset();
                }
              }}
            >
              Reset
            </Button>
          </DialogClose>

          <DialogClose asChild>
            <Button
              type="button"
              onClick={() => {
                if (is_api_match) {
                  on_apply(date_filter);
                } else {
                  handleSubmit();
                }
              }}
            >
              Save Changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
